html, body, #root, .App {
  width: 100%;
  height: 100%;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portrait {
  border-radius: 65%;
  /*border: darkgreen 6px solid;*/
  margin: 0 auto;

  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}